<template>
  <layout-default>
    <div class="container pb-5">
      <div class="my-account-page-location">
        <div class="my-account-breadcrumb border-bottom">
          <breadcrumb />
          <h1 class="my-account-title text-dark mt-1">Recuperar Contraseña</h1>
        </div>
        <section class="mt-5 w-75 mx-auto">
          <card>
            <template v-slot:title>
              <b>Recupera tu Contraseña</b>
            </template>
            <template v-slot:body>
              <section class="px-3">
                <h6 class="my-3">
                  Ingrese la dirección de correo electrónico de su cuenta de
                  usuario y le enviaremos un enlace para restablecer la
                  contraseña.
                </h6>
                <div class="">
                  <basic-label
                    title="Correo Electrónico"
                    class="fw-bold mb-1 mx-2 mt-4"
                  />
                  <basic-input-text
                    v-model="form.email"
                    type="email"
                    :hasRoundedCorners="true"
                    class="login-input my-2"
                    :placeholder="'Correo Electrónico'"
                    required
                  />
                </div>
              </section>
            </template>
            <template v-slot:footer>
              <button-outline
                @click="$router.push({ name: 'MyAccount' })"
                class="fw-bold mx-4"
                text="Volver"
                :hasRoundedCorners="true"
              >
              </button-outline>
              <button-filled
                class="send-btn text-white"
                :hasRoundedCorners="true"
                @click="sendLink"
              >
                <template v-slot:default>
                  <span class="">Enviar Correo de Restablecimiento</span>
                </template>
              </button-filled>
            </template>
          </card>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import Card from "@/components/molecules/Card/Card.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import ButtonOutline from "@/components/atoms/Button/ButtonOutline.vue";

export default {
  middleware: "guest",
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  beforeCreate() {
    document.title = "Recuperar Contraseña";
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Recuperar Contraseña", location: "/password/reset" },
    ]);
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    Card,
    BasicLabel,
    BasicInputText,
    ButtonFilled,
    ButtonOutline,
  },
  methods: {
    async sendLink() {
      if (!this.form.email) {
        return this.$swal({
          icon: "error",
          title: "Upss",
          text: "Ingrese un Correo Electrónico para enviar el link",
          showConfirmButton: true,
          timer: 4000,
        });
      }
      let loader = this.$loading.show();
      try {
        const { data } = await axios.post(
          "/api/auth/forgot-password",
          this.form
        );
        loader.hide();
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        const { status } = e.response || {};
        if (status === 422) {
          const { errors } = e.response.data;
          const err = errors[Object.keys(errors)[0]][0];
          this.$swal({
            icon: "error",
            title: "Disculpe",
            text: err,
            showConfirmButton: false,
            timer: 4000,
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
<style></style>
