<template>
  <div class="login-form d-flex w-100 flex-column border p-4 p-md-5">
    <div class="register-field">
      <BasicLabel title="Correo Electrónico" :required="true" class="fw-bold" />
      <BasicInputText
        type="email"
        :hasRoundedCorners="true"
        class="login-input mb-4"
        required
        v-model="form.email"
        :placeholder="'Correo Electrónico'"
      />
    </div>
    <div class="register-field-last mb-0">
      <BasicLabel title="Contraseña" class="fw-bold" :required="true" />
      <BasicInputText
        type="password"
        :hasRoundedCorners="true"
        class="login-input"
        v-model="form.password"
        required
        :placeholder="'Contraseña'"
      />
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between my-4"
    >
      <basic-label
        :title="'Recuerdame'"
        class="fw-bold"
        :forInput="'rememberMe'"
      >
        <template v-slot:before>
          <input
            class="form-check-input me-2"
            type="checkbox"
            value=""
            id="rememberMe"
            name="rememberMe"
          />
        </template>
      </basic-label>
      <link-wrapper
        :location="'/password/reset'"
        class="text-decoration-none fs-6 fw-bold"
      >
        <span>¿Olvidaste tu Contraseña?</span>
      </link-wrapper>
    </div>

    <div
      class="d-flex flex-row align-items-center justify-content-between my-4"
    >
      <ButtonOutline
        class="fw-bold"
        :text="'Regístrate'"
        @onClick="toRegister"
        :hasRoundedCorners="true"
      />

      <ButtonFilled
        type="submit"
        @click="login"
        class="fw-bold text-white"
        :text="'Iniciar Sesión'"
        :hasRoundedCorners="true"
      />
    </div>
  </div>
</template>

<script>
import ButtonFilled from "../../atoms/Button/ButtonFilled.vue";
import LinkWrapper from "../../atoms/LinkWrapper/LinkWrapper.vue";
import BasicLabel from "../../atoms/FormInput/BasicLabel.vue";
import BasicInputText from "../../atoms/FormInput/BasicInputText.vue";
import ButtonOutline from "../../atoms/Button/ButtonOutline.vue";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";
import loadingConfig from "@/constants/loadingConfig";

export default {
  name: "FormLogin",
  components: {
    ButtonFilled,
    LinkWrapper,
    BasicLabel,
    BasicInputText,
    ButtonOutline,
  },
  setup() {
    const $loading = useLoading();
    const state = reactive({
      form: {
        email: "",
        password: "",
      },
    });
    const router = useRouter();
    const store = useStore();

    const login = async () => {
      const loader = $loading.show(loadingConfig);
      const resp = await store.dispatch("auth/loginUser", state.form);
      if (resp.meta) {
        loader.hide();
        router.push({
          name: "Auctions",
        });
      }
      loader.hide();
    };

    const toRegister = () => {
      router.push({
        name: "Register",
      });
    };
    return { ...toRefs(state), toRegister, login };
  },
};
</script>
<style scoped="scoped">
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.Minicart_button {
  height: 40px !important;
  width: 150px !important;
}
.register-field-last {
  margin-top: 30px !important;
}
.register-field-last {
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
  .my-account-register {
    padding: 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input {
    width: 15px !important;
  }
}
</style>
