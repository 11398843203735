<template>
  <layout-default>
    <div class="container account-page">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
            <h1 class="my-account-title text-dark mt-1">Regístrate</h1>
          </div>
        </div>
        <div class="my-account d-flex justify-content-center">
          <div class="col-lg-8 col-md-12 col-sm-12 my-account-register">
            <FormRegister />
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import FormRegister from "@/components/molecules/Form/FormRegister.vue";

export default {
  middleware: ["guest"],
  beforeCreate() {
    document.title = "Regístrate";
  },
  components: { LayoutDefault, FormRegister, Breadcrumb },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Regístrate", location: "/register" },
    ]);
  },
};
</script>
<style></style>
