<template>
  <layout-default>
    <section class="container p-5">
      <card class="w-75 mx-auto">
        <template v-slot:title>
          <h5>Restablecer la Contraseña</h5>
        </template>
        <template v-slot:body>
          <div class="container d-flex flex-column">
            <div class="my-account d-flex justify-content-center">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <form
                  ref="formUser"
                  @submit.prevent="reset"
                  class="p-md-4 login-form d-flex flex-column"
                >
                  <div class="row mt-4">
                    <div class="col-md-6 col-sm-12">
                      <basic-label title="Contraseña" class="fw-bold" />
                      <basic-input-text
                        :hasRoundedCorners="true"
                        class="login-input mb-2"
                        v-model="v$.form.password.$model"
                        type="password"
                        :placeholder="'Contraseña'"
                      />
                      <span class="text-danger" v-if="v$.form.password.$error"
                        >La Contraseña es requerida y debe tener al menos 6
                        caracteres.</span
                      >
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <BasicLabel
                        title="Repita su contraseña"
                        class="fw-bold"
                      />
                      <BasicInputText
                        :hasRoundedCorners="true"
                        class="login-input mb-2"
                        type="password"
                        v-model="v$.form.password_confirmation.$model"
                        :placeholder="'Repita su contraseña'"
                      />
                      <span
                        class="text-danger"
                        v-if="v$.form.password_confirmation.$error"
                        >Las contraseñas no coinciden.</span
                      >
                    </div>
                  </div>

                  <div class="d-flex justify-content-end mt-4">
                    <button-filled
                      type="submit"
                      class="text-white"
                      text="Restablecer"
                      :hasRoundedCorners="true"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </template>
      </card>
    </section>
  </layout-default>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, sameAs, minLength } from "@vuelidate/validators";
import { mapActions } from "vuex";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Card from "@/components/molecules/Card/Card.vue";

export default {
  components: { BasicLabel, BasicInputText, ButtonFilled, LayoutDefault, Card },
  name: "PasswordForgot",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: null,
        password_confirmation: null,
        token: this.$route.params.token,
      },
      valid: true,
    };
  },
  validations() {
    return {
      form: {
        password: { required, minLengthValue: minLength(6) },
        password_confirmation: {
          sameAsPassword: sameAs(this.form.password),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      sendResetPassword: "auth/sendResetPassword",
    }),
    async reset() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        let loader = this.$loading.show();
        try {
          const { data } = await this.sendResetPassword(this.form);
          loader.hide();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$router.push({ name: "MyAccount", params: { id: null } });
          });
        } catch (e) {
          const { status } = e.response || {};
          if (status === 422) {
            const { errors } = e.response.data;
            const err = errors[Object.keys(errors)[0]][0];
            this.$swal({
              icon: "error",
              title: "Disculpe",
              text: err,
              showConfirmButton: false,
              timer: 4000,
            });
          }
          loader.hide();
        }
      }
    },
  },
};
</script>

<style></style>
